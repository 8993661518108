import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../images/default_logo.png"

const RssCompany = ({ data, pageContext, location }) => {
  return (
    <div>
      <Layout location={location} title='DXデイリーポスト'>
        <SEO title="世界の日々の最新DX情報を日本語で" description="" />  
        <div key={data.strapiCompanyNewsRssUrls.id}>
          {data.allStrapiScrapingData.edges.map(scraping_datum => (
            <div>
            <div className="dx-row">
              <div className="dx-column left"> 
                { data?.strapiCompanyNewsRssUrls?.Logo?.publicURL
                    ? <img className="company-img" src={`${data?.strapiCompanyNewsRssUrls?.Logo?.publicURL}`} alt={`${data.strapiCompanyNewsRssUrls.companyname}_logo`} />
                    : <>
                      <img className="company-img" src={Logo} 
                        alt="logo" />  
                      </>
                }
              </div>
              {/* end of logo */}
              <div className="dx-column middle">
                <div>
                <p className="newdate mobile"><b>{scraping_datum.node.releasedate} &nbsp;&nbsp;{scraping_datum.node.companyname}</b></p>
                <p>{scraping_datum.node.newstitle}</p>
                <p><a href={`${scraping_datum.node.link}`} target="_blank" className="mobile">Read More</a></p>
                </div>
              </div>
              {/* end of news title */}
              <div className="dx-column right">
                <p className="newdate"><b>{scraping_datum.node.releasedate} &nbsp;&nbsp;{scraping_datum.node.companyname}</b></p><br/>
                <p><a href={`${scraping_datum.node.link}`} target="_blank">Read More</a></p>
              </div>
            </div>
            <hr/>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default RssCompany;

export const query = graphql`
  query GetRssCompanyData($id: String, $slug: String) {
    strapiCompanyNewsRssUrls(id: {eq: $id}) {
      CompanyName
      id
      Logo {
        publicURL
      }
    }
    allStrapiScrapingData(filter: {companyname: {eq: $slug}}, sort: {fields: releasedate, order: DESC}, limit: 100) {
      edges {
        node {
          id
          releasedate
          companyname
          newstitle
          link
        }
      }
    }
  }
  `

